<script setup lang="ts">
  const localePath = useLocalePath();
  const route = useRoute();
  const isTransactionReplenishment = computed(() => route.name === Page.TRANSACTION_REPLENISHMENT);
</script>

<template>
  <header class="transaction-header flex-col">
    <section class="flex-c-b">
      <h1>Транзакции</h1>
      <transaction-header-actions></transaction-header-actions>
    </section>
    <div class="flex-c-b">
      <transaction-navigation></transaction-navigation>
      <transaction-header-replenishment v-if="isTransactionReplenishment"></transaction-header-replenishment>
      <!-- <nuxt-link v-else :to="localePath(Page.ORDER)">
        <common-button text="Перевести" class-mods="btn-accent btn-fit btn-small"></common-button>
      </nuxt-link> -->
    </div>
  </header>
</template>

<style scoped lang="scss">
.transaction-header {
  @apply gap-4;
}
</style>
