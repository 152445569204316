<script setup lang="ts">
  // import { navigationTransactionList } from '@/utils/data/navigation';
  import { NavigationItem } from '@/interfaces';

  const { t } = useI18n();

  const navigationTransactionList = computed<NavigationItem[]>(() => [
    { path: Page.TRANSACTION_REPLENISHMENT, name: t('components.common.header.replenishments') },
    { path: Page.TRANSACTION_PAYMENTS, name: t('components.common.header.payments') },
    // { path: Page.TRANSACTION_TRANSFER, name: t('components.common.header.transfers') },
  ]);

  const localePath = useLocalePath();
</script>

<template>
  <nav class="transaction-navigation">
    <ul class="flex flex-ic">
      <li v-for="item of navigationTransactionList" :key="item.path">
        <nuxt-link
          v-if="item.path"
          class="flex-c-b"
          active-class="active"
          :to="localePath(item.path!)"
        >
          <span>{{ item.name }}</span>
        </nuxt-link>
      </li>
    </ul>
  </nav>
</template>

<style scoped lang="scss">
.transaction-navigation {
  @apply p-1 rounded-md bg-gray-5 text-sm w-fit;
  ul {
    li {
      a {
        @apply text-gray-2 font-medium rounded-md py-1.5 px-3;
        &.active {
          @apply bg-white text-black;
        }

      }
    }
  }
}
</style>
